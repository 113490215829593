import { CheckIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { useEffect } from 'react'
import { ThreeDots } from 'react-loader-spinner'
import Link from 'next/link'
import priceTypes from '@/constants/price-types'
import PricingCta from '@/components/landing-page/PricingCta'
import SavingsX from '@/components/landing-page/SavingsX'
import { useExitIntent } from 'use-exit-intent'
import LeavingModal from '@/components/landing-page/LeavingModal'
import HoldOnCta from './HoldOnCta'
import { useSelector } from 'react-redux'
// import exitntent from 'exit-intent'
const PRICING = {
  500: { USD: 5, INR: 400 },
  1000: { USD: 8, INR: 650 },
  3000: { USD: 12, INR: 950 },
  5000: { USD: 18, INR: 1450 },
  10000: { USD: 23, INR: 1900 },
  20000: { USD: 36, INR: 2950 },
  30000: { USD: 50, INR: 4150 },
  40000: { USD: 63, INR: 5200 },
  50000: { USD: 72, INR: 5950 },
  60000: { USD: 85, INR: 7050 },
  70000: { USD: 100, INR: 8300 },
  80000: { USD: 112, INR: 9300 },
  90000: { USD: 126, INR: 10450 },
  100000: { USD: 135, INR: 11200 },
  120000: { USD: 153, INR: 12700 },
  140000: { USD: 171, INR: 14150 },
  160000: { USD: 189, INR: 15650 },
  180000: { USD: 207, INR: 17150 },
  200000: { USD: 225, INR: 18650 },
  210000: { USD: '-', INR: '-' },
}

const REGULAR_PRICING = {
  500: { USD: 7, INR: 550 },
  1000: { USD: 9, INR: 700 },
  3000: { USD: 15, INR: 1200 },
  5000: { USD: 22, INR: 1800 },
  10000: { USD: 32, INR: 2650 },
  15000: { USD: 45, INR: 3700 },
  20000: { USD: 58, INR: 4800 },
  25000: { USD: 72, INR: 5950 },
  30000: { USD: 81, INR: 6700 },
  35000: { USD: 95, INR: 7850 },
  40000: { USD: 108, INR: 8950 },
  45000: { USD: 122, INR: 10100 },
  50000: { USD: 135, INR: 11200 },
  55000: { USD: 144, INR: 11950 },
  60000: { USD: 158, INR: 13100 },
  65000: { USD: 171, INR: 14150 },
  70000: { USD: 185, INR: 15350 },
  75000: { USD: 198, INR: 16400 },
  80000: { USD: 207, INR: 17150 },
  85000: { USD: 220, INR: 18250 },
  90000: { USD: 234, INR: 19400 },
  95000: { USD: 248, INR: 20550 },
  100000: { USD: 261, INR: 21650 },
  120000: { USD: 306, INR: 25400 },
  140000: { USD: 351, INR: 29100 },
  160000: { USD: 396, INR: 32850 },
  180000: { USD: 432, INR: 35850 },
  200000: { USD: 477, INR: 39600 },
  210000: { USD: '-', INR: '-' },
}

const downgradeClasess =
  'w-full text-center transition mt-6 block rounded-md border text-red-600 border-red-600 py-2 px-3 text-center text-sm font-semibold leading-6  hover:text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600'
const currentPlanClasses =
  'w-full text-center transition mt-6 block rounded-md text-indigo-600 border hover:border-indigo-400 border-indigo-600 py-2 px-3 text-center text-sm font-semibold leading-6 hover:bg-indigo-400 hover:text-white shadow-sm hover:bg-white-500 focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-1 focus-visible:outline-indigo-100'
const changePlanClasses =
  'w-full text-center transition mt-6 block rounded-md bg-indigo-600 py-2 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Pricing2() {
  const [priceType, setPriceType] = useState(priceTypes[0])
  const { unsubscribe, registerHandler } = useExitIntent({
    desktop: {
      delayInSecondsToTrigger: 2,
      triggerOnMouseLeave: true,
      triggerOnIdle: true,
      useBeforeUnload: false,
    },
  })

  const [open, setOpen] = useState(false)
  registerHandler({
    id: 'openModal',
    handler: () => setOpen(true),
  })

  return (
    <>
      <LeavingModal open={open} setOpen={setOpen} />
      {/* <NextSeo
      title="Pricing | CampaignHQ"
      description="CampaignHQ pricing plans"
      openGraph={{
        url: 'https://campaignhq.co/pricing',
        title: 'Pricing | CampaignHQ',
        description: 'CampaignHQ pricing plans',
        images: [
          {
            url: 'https://campaignhq-prod-public.s3.ap-southeast-1.amazonaws.com/ss-chq.png',
            width: 1200,
            height: 630,
            alt: 'CampaignHQ',
          },
        ],
        site_name: 'CampaignHQ',
        locale: 'en_US',
        type: 'article',
      }}
      twitter={{
        handle: '@campaignhqco',
        site: '@campaignhqco',

        cardType: 'summary_large_image',
      }}
    /> */}
      {/* <ProductJsonLd
      product={jsonLd}
    /> */}

      <div className="relative isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div
          className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl"
          aria-hidden="true"
        >
          <div
            className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
          {/* <h2 className="text-base font-semibold leading-7 text-indigo-600">
          Simple pricing
        </h2> */}
          <h1 className="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Reduce email marketing costs <br /> upto 50%
          </h1>
        </div>
        {/* <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
        Looking for a pricing plan that can keep up with your growing business?
        Look no further than CampaignHQ
      </p> */}

        <div className="mt-8 flex justify-center">
          <Link
            href="/savings-calculator"
            className="rounded border bg-white/60 p-10 font-semibold text-indigo-600 hover:text-indigo-500"
            alt="compare CampaignHQ pricing with other email marketing tools like mailchimp, mailmodo, mailerlite, sendinblue, sendgrid, campaign monitor, constant contact, drip, convertkit, getresponse, activecampaign, aweber, klaviyo, omnisend, sendpulse, mailjet, mailgun, moosend, sendx, sendy"
          >
            <div className="mx-auto mt-2 text-center">
              See how much you can save {'->'}
            </div>
            <SavingsX></SavingsX>
          </Link>
        </div>

        <div className="mt-8 flex justify-center">
          <RadioGroup
            value={priceType}
            onChange={setPriceType}
            className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-sm font-semibold leading-5 ring-1 ring-inset ring-gray-300"
          >
            <RadioGroup.Label className="sr-only">
              Account type
            </RadioGroup.Label>
            {priceTypes.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ checked }) =>
                  classNames(
                    checked ? 'bg-indigo-600 text-white' : 'text-gray-600',
                    'cursor-pointer rounded-full px-2.5 py-1'
                  )
                }
              >
                <span>{option.label}</span>
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
        {/* <div className="mx-auto mt-5 max-w-lg text-center text-sm text-gray-500">
          {priceType.description}
        </div> */}
        {/* <div className="mx-auto mt-8 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-10 sm:gap-y-0 lg:max-w-3xl lg:grid-cols-1"> */}
        {/* {priceType.value == 'regular' ? <FreeRegularPlan /> : <FreePlan />} */}
        {/* {priceType.value == 'regular' ? (
          <PricingCalculatorRegular />
        ) : (
          <PricingCalculator />
        )} */}
        {priceType.value == 'regular' ? (
          <PricingV2CalculatorRegular />
        ) : (
          <PricingV2ConnectCalculator />
        )}

        {/* <PricingCalculatorRegular /> */}
        {/* <PricingCalculator /> */}
        {/* </div> */}
      </div>
      <HoldOnCta />
      {/* <PricingCta></PricingCta> */}
    </>
  )
}

export const FreeRegularPlan = function ({
  selectPlan,
  currentPlan,
  currentSubscribers,
  loading,
}) {
  function cta() {
    if (selectPlan) {
      if (!currentPlan) {
        return (
          <button
            onClick={() =>
              selectPlan({ plan_id: 'free-regular', subscribers: 3000 })
            }
            className={changePlanClasses}
          >
            Start free trial
          </button>
        )
      }

      return (
        <button
          onClick={() =>
            selectPlan({ plan_id: 'free-regular', subscribers: 3000 })
          }
          disabled={currentPlan?.slug == 'free-regular'}
          className={
            currentPlan?.slug != 'free-regular'
              ? downgradeClasess
              : currentPlanClasses
          }
        >
          {currentPlan?.slug != 'free-regular' ? 'Downgrade' : 'Current Plan'}
        </button>
      )
    }

    return (
      <a
        href="/signup"
        className={classNames(
          'mt-6 block rounded-md bg-indigo-600 py-2 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
        )}
      >
        Get Started
      </a>
    )
  }

  return (
    <div className="rounded-3xl bg-white/60 p-8 ring-1 ring-gray-900/10 sm:mx-8 sm:rounded-t-none sm:p-10 lg:mx-0 lg:rounded-tl-3xl lg:rounded-br-none">
      <p className="mt-6 flex items-baseline gap-x-1">
        <span className="text-3xl font-bold tracking-tight text-gray-900">
          Free
        </span>
      </p>
      <div className="mt-4 text-xl font-semibold leading-6 text-gray-500">
        3,000 <span className="text-sm font-normal">subscribers</span>
      </div>
      <div className="mt-2 text-xl font-semibold leading-6 text-gray-500">
        10,000 <span className="text-sm font-normal">emails per month</span>
      </div>

      {loading ? (
        <button
          disabled={loading}
          className={
            currentPlan?.slug == 'free' ? currentPlanClasses : downgradeClasess
          }
        >
          <span className="inline-block">
            <ThreeDots
              height="10"
              width="30"
              radius="10"
              color={currentPlan?.slug != 'free' ? '#dc2625' : '#0075ff'}
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            ></ThreeDots>
          </span>
        </button>
      ) : (
        cta()
      )}

      <ul
        role="list"
        className="mt-8 space-y-3 text-sm leading-6 text-gray-600"
      >
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          100 emails limit per day
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          100 WhatsApp message limit per day
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          Limited email verifications
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          Access for 1 user
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          Connect 1 email service provider
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          Reports available for a month
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          CampaignHQ branding on emails
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          AI assisted campaign creation
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          Email support
        </li>
      </ul>
    </div>
  )
}

export const FreePlan = function ({
  selectPlan,
  currentPlan,
  currentSubscribers,
  loading,
}) {
  function cta() {
    if (selectPlan) {
      if (!currentPlan) {
        return (
          <button
            onClick={() => selectPlan({ plan_id: 'free', subscribers: 3000 })}
            className={changePlanClasses}
          >
            Start free trial
          </button>
        )
      }

      return (
        <button
          onClick={() => selectPlan({ plan_id: 'free', subscribers: 3000 })}
          disabled={currentPlan?.slug == 'free'}
          className={
            currentPlan?.slug != 'free' ? downgradeClasess : currentPlanClasses
          }
        >
          {currentPlan?.slug != 'free' ? 'Downgrade' : 'Current Plan'}
        </button>
      )
    }

    return (
      <a
        href="/signup"
        className={classNames(
          'mt-6 block rounded-md bg-indigo-600 py-2 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
        )}
      >
        Get Started
      </a>
    )
  }

  return (
    <div className="rounded-3xl bg-white/60 p-8 ring-1 ring-gray-900/10 sm:mx-8 sm:rounded-t-none sm:p-10 lg:mx-0 lg:rounded-tl-3xl lg:rounded-br-none">
      <p className="mt-6 flex items-baseline gap-x-1">
        <span className="text-3xl font-bold tracking-tight text-gray-900">
          Free
        </span>
      </p>
      <div className="mt-4 text-xl font-semibold leading-6 text-gray-500">
        3,000 <span className="text-sm font-normal">subscribers</span>
      </div>
      <div className="mt-2 text-xl font-semibold leading-6 text-gray-500">
        Unlimited <span className="text-sm font-normal">emails per month</span>
      </div>
      <div className="mt-2 text-xl font-semibold leading-6 text-gray-500">
        Unlimited <span className="text-sm font-normal">WhatsApp messages</span>
      </div>

      {loading ? (
        <button
          disabled={loading}
          className={
            currentPlan?.slug == 'free' ? currentPlanClasses : downgradeClasess
          }
        >
          <span className="inline-block">
            <ThreeDots
              height="10"
              width="30"
              radius="10"
              color={currentPlan?.slug != 'free' ? '#dc2625' : '#0075ff'}
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            ></ThreeDots>
          </span>
        </button>
      ) : (
        cta()
      )}

      <ul
        role="list"
        className="mt-8 space-y-3 text-sm leading-6 text-gray-600"
      >
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          Emails routed through your own Amazon SES account
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          Limited email verifications
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          Access for 1 user
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          Connect 1 email service provider
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          Reports available for a month
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          CampaignHQ Email templates
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          CampaignHQ branding on emails
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          AI assisted campaign creation
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          Email support
        </li>
      </ul>
    </div>
  )
}

// Not used
export const PricingCalculator = function ({
  selectPlan,
  currentPlan,
  currentSubscribers,
  loading,
}) {
  const [selectedSubs, setSelectedSubs] = useState(500)
  const [contactSales, setContactSales] = useState(false)
  const [price, setPrice] = useState(5)
  let nf = new Intl.NumberFormat('en-US')

  useEffect(() => {
    if (currentSubscribers && currentPlan?.slug == 'premium') {
      setSelectedSubs(currentSubscribers)
      setPrice(PRICING[currentSubscribers])
    }
  }, [])

  const openChat = function () {
    if (Beacon) {
      Beacon('open')
    }
  }

  function handleSubsChange(e) {
    const value = e.target.value

    if (value > 200000) {
      setContactSales(true)
    } else {
      setContactSales(false)
    }

    const result = Object.keys(PRICING).find((key) => {
      return value <= parseInt(key)
    })

    setPrice(PRICING[result])
    setSelectedSubs(result)
  }

  function handleBuyClick() {
    if (contactSales) {
      openChat()
      return
    }
    router.push('/signup')
  }

  function handleSelectPlan() {
    if (currentPlan?.slug == 'premium' && selectedSubs == currentSubscribers)
      return
    selectPlan({ plan_id: 'premium', subscribers: selectedSubs })
  }

  function cta() {
    if (selectPlan) {
      if (!currentPlan) {
        return (
          <a href="#" onClick={handleSelectPlan} className={changePlanClasses}>
            Start free trial
          </a>
        )
      }

      if (currentPlan?.slug != 'premium') {
        return (
          <a href="#" onClick={handleSelectPlan} className={changePlanClasses}>
            Change Plan
          </a>
        )
      }

      return (
        <a
          href="#"
          onClick={handleSelectPlan}
          className={
            currentSubscribers != selectedSubs
              ? changePlanClasses
              : currentPlanClasses
          }
        >
          {currentSubscribers != selectedSubs ? 'Change Plan' : 'Current plan'}
        </a>
      )
    }

    if (contactSales) {
      return (
        <a
          href="#"
          onClick={handleBuyClick}
          className={classNames(
            'mt-6 block rounded-md bg-indigo-600 py-2 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
          )}
        >
          Contact us
        </a>
      )
    }

    return (
      <a
        href="/signup"
        className={classNames(
          'mt-6 block rounded-md bg-indigo-600 py-2 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
        )}
      >
        Start free trial
      </a>
    )
  }
  return (
    <div className="relative rounded-3xl bg-white p-8 ring-1 ring-gray-900/10 sm:p-10">
      <h3
        className={classNames(
          'text-lg font-semibold leading-8 text-indigo-600'
        )}
      >
        <p className="mt-6 flex items-baseline gap-x-1">
          <span className="text-3xl font-bold tracking-tight text-gray-900">
            Premium
          </span>
        </p>
      </h3>
      {/* <p className="text-sm leading-6 text-gray-600">Build you own plan</p> */}
      <p className="mt-6 flex items-baseline gap-x-1">
        <span className="text-4xl font-bold tracking-tight text-gray-900">
          ${price}
        </span>
        <span className="text-sm font-semibold leading-6 text-gray-600">
          /month
        </span>
      </p>
      <div>
        <label htmlFor="steps-range" className="sr-only">
          Range steps
        </label>
        <input
          id="steps-range"
          type="range"
          min="500"
          value={selectedSubs}
          max="201000"
          onChange={handleSubsChange}
          step="500"
          className="mt-6 h-3 w-full cursor-pointer appearance-none rounded-lg border bg-white accent-indigo-600"
        />
      </div>
      <div className="mt-4 text-xl font-semibold leading-6 text-gray-500">
        {selectedSubs > 200000 ? '200,000+' : nf.format(selectedSubs)}{' '}
        <span className="text-sm font-normal">subscribers</span>
      </div>
      <div className="mt-2 text-xl font-semibold leading-6 text-gray-500">
        Unlimited <span className="text-sm font-normal">emails per month</span>
      </div>
      <div className="mt-2 text-xl font-semibold leading-6 text-gray-500">
        Unlimited{' '}
        <span className="text-sm font-normal"> WhatsApp per month </span>
      </div>
      {loading ? (
        <button
          disabled={loading}
          className={
            currentSubscribers != selectedSubs
              ? changePlanClasses
              : currentPlanClasses
          }
        >
          <span className="inline-block">
            <ThreeDots
              height="10"
              width="30"
              radius="10"
              color={currentPlan?.slug == 'premium' ? '#0075ff' : '#fff'}
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            ></ThreeDots>
          </span>
        </button>
      ) : (
        cta()
      )}
      <ul
        role="list"
        className="mt-8 space-y-3 text-sm leading-6 text-gray-600"
      >
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          Emails routed through your own Amazon SES account
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          Unlimited WhatsApp messages
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          Unlimited automation workflows
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          Unlimited Segmentations
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          Unlimited Custom fields
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          AI assisted email subject line suggestions
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          CampaignHQ Email templates
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          5 users included
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          AI assisted campaign creation
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          Reports available forever
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          Live customer support & setup assistance
        </li>
      </ul>
    </div>
  )
}

// Not used now
export const PricingCalculatorRegular = function ({
  selectPlan,
  currentPlan,
  currentSubscribers,
  loading,
}) {
  const [selectedSubs, setSelectedSubs] = useState(500)
  const [contactSales, setContactSales] = useState(false)
  const [emails, setEmails] = useState(10000)
  const [price, setPrice] = useState(7)
  let nf = new Intl.NumberFormat('en-US')

  useEffect(() => {
    if (currentSubscribers && currentPlan?.slug == 'premium-regular') {
      setSelectedSubs(currentSubscribers)
      setPrice(REGULAR_PRICING[currentSubscribers])
    }
  }, [])

  const openChat = function () {
    if (Beacon) {
      Beacon('open')
    }
  }

  function handleSubsChange(e) {
    const value = e.target.value

    if (value > 200000) {
      setContactSales(true)
    } else {
      setContactSales(false)
    }

    const result = Object.keys(REGULAR_PRICING).find((key) => {
      return value <= parseInt(key)
    })

    setPrice(REGULAR_PRICING[result])
    setSelectedSubs(result)
    setEmails(result * 10)
  }

  function handleBuyClick() {
    if (contactSales) {
      openChat()
      return
    }
    router.push('/signup')
  }

  function handleSelectPlan() {
    if (
      currentPlan?.slug == 'premium-regular' &&
      selectedSubs == currentSubscribers
    )
      return
    selectPlan({ plan_id: 'premium-regular', subscribers: selectedSubs })
  }

  function cta() {
    if (selectPlan) {
      if (!currentPlan) {
        return (
          <a href="#" onClick={handleSelectPlan} className={changePlanClasses}>
            Start free trial
          </a>
        )
      }

      if (currentPlan?.slug != 'premium-regular') {
        return (
          <a href="#" onClick={handleSelectPlan} className={changePlanClasses}>
            Change Plan
          </a>
        )
      }

      return (
        <a
          href="#"
          onClick={handleSelectPlan}
          className={
            currentSubscribers != selectedSubs
              ? changePlanClasses
              : currentPlanClasses
          }
        >
          {currentSubscribers != selectedSubs ? 'Change Plan' : 'Current plan'}
        </a>
      )
    }

    if (contactSales) {
      return (
        <a
          href="#"
          onClick={handleBuyClick}
          className={classNames(
            'mt-6 block rounded-md bg-indigo-600 py-2 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
          )}
        >
          Contact us
        </a>
      )
    }

    return (
      <a
        href="/signup"
        className={classNames(
          'mt-6 block rounded-md bg-indigo-600 py-2 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
        )}
      >
        Start free trial
      </a>
    )
  }
  return (
    <div className="relative rounded-3xl bg-white p-8 ring-1 ring-gray-900/10 sm:p-10">
      <h3
        className={classNames(
          'text-lg font-semibold leading-8 text-indigo-600'
        )}
      >
        <p className="mt-6 flex items-baseline gap-x-1">
          <span className="text-3xl font-bold tracking-tight text-gray-900">
            Premium
          </span>
        </p>
      </h3>
      {/* <p className="text-sm leading-6 text-gray-600">Build you own plan</p> */}
      <p className="mt-6 flex items-baseline gap-x-1">
        <span className="text-4xl font-bold tracking-tight text-gray-900">
          ${price}
        </span>
        <span className="text-sm font-semibold leading-6 text-gray-600">
          /month
        </span>
      </p>
      <div>
        <label htmlFor="steps-range" className="sr-only">
          Range steps
        </label>
        <input
          id="steps-range"
          type="range"
          min="500"
          value={selectedSubs}
          max="201000"
          onChange={handleSubsChange}
          step="500"
          className="mt-6 h-3 w-full cursor-pointer appearance-none rounded-lg border bg-white accent-indigo-600"
        />
      </div>
      <div className="mt-4 text-xl font-semibold leading-6 text-gray-500">
        {selectedSubs > 200000 ? '200,000+' : nf.format(selectedSubs)}{' '}
        <span className="text-sm font-normal">subscribers</span>
      </div>
      <div className="mt-2 text-xl font-semibold leading-6 text-gray-500">
        {selectedSubs > 200000 ? '2,000,000+' : nf.format(emails)}{' '}
        <span className="text-sm font-normal">emails per month</span>
      </div>
      <div className="mt-2 text-xl font-semibold leading-6 text-gray-500">
        Unlimited{' '}
        <span className="text-sm font-normal">Whatsapp messages per month</span>
      </div>
      {loading ? (
        <button
          disabled={loading}
          className={
            currentSubscribers != selectedSubs
              ? changePlanClasses
              : currentPlanClasses
          }
        >
          <span className="inline-block">
            <ThreeDots
              height="10"
              width="30"
              radius="10"
              color={currentPlan?.slug == 'premium' ? '#0075ff' : '#fff'}
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            ></ThreeDots>
          </span>
        </button>
      ) : (
        cta()
      )}
      <ul
        role="list"
        className="mt-8 space-y-3 text-sm leading-6 text-gray-600"
      >
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          Unlimited automation workflows
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          Unlimited Segmentations
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          Unlimited Custom fields
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          AI assisted email subject line suggestions
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          CampaignHQ Email templates
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          5 users included
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          AI assisted campaign creation
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          Reports available forever
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          Live customer support & setup assistance
        </li>
      </ul>
    </div>
  )
}

// currently using
export const PricingV2CalculatorRegular = function ({
  selectPlan,
  currentPlan,
  currentSubscribers,
  loading,
  currentCurrency = 'USD',
  showCurrency = true,
}) {
  const [selectedSubs, setSelectedSubs] = useState(500)
  const [contactSales, setContactSales] = useState(false)
  const [emails, setEmails] = useState(10000)
  const [price, setPrice] = useState(7)
  const [currency, setCurrency] = useState(currentCurrency)
  let nf = new Intl.NumberFormat('en-US')
  const currentUser = useSelector((state) => state.sessions.currentUser)

  const getClosestMinimumSubscribers = (value) => {
    let result = null;
    Object.keys(PRICING).forEach((key) => {
      result = Math.min(value, parseInt(key)) || parseInt(key)
    })
    return result
  }

  useEffect(() => {
    if (currentSubscribers && currentPlan?.slug == 'premium-regular') {
      setSelectedSubs(currentSubscribers)
      const subscribers = getClosestMinimumSubscribers(currentSubscribers)

      setPrice(REGULAR_PRICING[subscribers][currency])
    } else {
      setPrice(REGULAR_PRICING[selectedSubs][currency])
    }
  }, [currency])

  const openChat = function () {
    if (Beacon) {
      Beacon('open')
    }
  }

  function handleSubsChange(e) {
    const value = e.target.value

    if (value > 200000) {
      setContactSales(true)
    } else {
      setContactSales(false)
    }

    const result = Object.keys(REGULAR_PRICING).find((key) => {
      return value <= parseInt(key)
    })

    setPrice(REGULAR_PRICING[result][currency])
    setSelectedSubs(result)
    setEmails(result * 10)
  }

  function handleBuyClick() {
    if (contactSales) {
      openChat()
      return
    }
    router.push('/signup')
  }

  function handleSelectPlan() {
    if (
      currentUser.company?.subscriptionStatus != 'trial' &&
      currentPlan?.slug == 'premium-regular' &&
      selectedSubs == currentSubscribers
    )
      return
    selectPlan({
      plan_id: 'premium-regular',
      subscribers: selectedSubs,
      currency: currency,
    })
  }

  function cta() {
    if (selectPlan) {
      if (!currentPlan) {
        return (
          <a href="#" onClick={handleSelectPlan} className={changePlanClasses}>
            Start free trial
          </a>
        )
      }

      if (currentPlan?.slug != 'premium-regular') {
        return (
          <a href="#" onClick={handleSelectPlan} className={changePlanClasses}>
            Change Plan
          </a>
        )
      }

      return (
        <a
          href="#"
          onClick={handleSelectPlan}
          className={
            currentUser.company?.subscriptionStatus == 'trial'
              ? changePlanClasses
              : currentSubscribers != selectedSubs
              ? changePlanClasses
              : currentPlanClasses
          }
        >
          {currentUser.company?.subscriptionStatus == 'trial'
            ? 'Start subscription'
            : currentSubscribers != selectedSubs
            ? 'Change plan'
            : 'Current plan'}
        </a>
      )
    }

    if (contactSales) {
      return (
        <a
          href="#"
          onClick={handleBuyClick}
          className={classNames(
            'mt-6 block rounded-md bg-indigo-600 py-2 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
          )}
        >
          Contact us
        </a>
      )
    }

    return (
      <a
        href="/signup"
        className={classNames(
          'mt-6 block rounded-md bg-indigo-600 py-2 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
        )}
      >
        Start free trial
      </a>
    )
  }

  const includedFeatures = [
    'Unlimited automation workflows',
    'Unlimited Segmentations',
    'Unlimited custom fields',
    'AI assisted email subject line suggestions',
    'CampaignHQ email templates',
    '5 users included',
    'AI assisted campaign creation',
    'Reports available forever',
    'Live customer support & setup assistance',
    'Many more features',
  ]

  const currencies = ['USD', 'INR']
  const currencySymbols = {
    USD: '$',
    INR: '₹',
  }

  const handleCurrencyChange = (e) => {
    setCurrency(e.target.value)
  }

  return (
    <div className="">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl rounded-3xl ring-1 ring-gray-200 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">
              Premium Regular
            </h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Unleash the power of CampaignHQ managed email servers - No setup
              needed! Focus on business growth, we'll handle the rest!
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">
                Everything included
              </h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {includedFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon
                    className="h-6 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0 ">
            <div className="relative h-full rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8 ">
                <div className="text-sm font-semibold text-indigo-600">
                  <p className="text-md text-bold absolute left-0 top-0 right-0 rounded-t-2xl bg-green-500 px-3 py-2 leading-5 text-white">
                    No credit card required, cancel anytime
                  </p>
                  <div>7 days free trial</div>
                  <div className="mt-2 text-xs font-normal leading-5 text-gray-600">
                    100 subscribers & 100 emails per day limit in trial period
                  </div>
                  {showCurrency ? (
                    <div className="mt-6">
                      <select
                        id="currency"
                        name="currency"
                        className="mx-auto block w-1/3 rounded-md border-0 py-1 pl-3 pr-3 text-xs text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-indigo-600 sm:leading-6"
                        defaultValue={currency}
                        onChange={handleCurrencyChange}
                      >
                        {currencies.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : null}
                </div>
                <p className="mt-4 flex items-baseline justify-center gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">
                    {currencySymbols[currency]}
                    {nf.format(price)}
                  </span>
                  <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                    /month {currency == 'INR' ? '+ GST' : ''}
                  </span>
                </p>
                <div>
                  <label htmlFor="steps-range" className="sr-only">
                    Range steps
                  </label>
                  <input
                    id="steps-range"
                    type="range"
                    min="500"
                    value={selectedSubs}
                    max="201000"
                    onChange={handleSubsChange}
                    step="500"
                    className="mt-6 h-3 w-full cursor-pointer appearance-none rounded-lg border bg-white accent-indigo-600"
                  />
                </div>
                <div className="mt-4 text-left text-xl font-semibold leading-6 text-gray-500">
                  {selectedSubs > 200000 ? '200,000+' : nf.format(selectedSubs)}{' '}
                  <span className="text-sm font-normal">subscribers</span>
                </div>
                <div className="mt-2 text-left text-xl font-semibold leading-6 text-gray-500">
                  {selectedSubs > 200000 ? '2,000,000+' : nf.format(emails)}{' '}
                  <span className="text-sm font-normal">emails per month</span>
                </div>
                <div className="mt-2 text-left text-xl font-semibold leading-6 text-gray-500">
                  <span className="text-sm font-normal">
                    Whatsapp included (per message charges apply)
                  </span>
                </div>
                {loading ? (
                  <button
                    disabled={loading}
                    className={
                      currentSubscribers != selectedSubs
                        ? changePlanClasses
                        : currentPlanClasses
                    }
                  >
                    <span className="inline-block">
                      <ThreeDots
                        height="10"
                        width="30"
                        radius="10"
                        color={
                          currentPlan?.slug == 'premium' ? '#0075ff' : '#fff'
                        }
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      ></ThreeDots>
                    </span>
                  </button>
                ) : (
                  cta()
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// used
export const PricingV2ConnectCalculator = function ({
  selectPlan,
  currentPlan,
  currentSubscribers,
  loading,
  currentCurrency = 'USD',
  showCurrency = true,

}) {
  const [selectedSubs, setSelectedSubs] = useState(500)
  const [contactSales, setContactSales] = useState(false)
  const [price, setPrice] = useState(5)
  const [currency, setCurrency] = useState(currentCurrency)
  let nf = new Intl.NumberFormat('en-US')
  const currentUser = useSelector((state) => state.sessions.currentUser)
  const getClosestMinimumSubscribers = (value) => {
    let result = null;
    Object.keys(PRICING).forEach((key) => {
      result = Math.min(value, parseInt(key)) || parseInt(key)
    })
    return result
  }
  useEffect(() => {
    if (currentSubscribers && currentPlan?.slug == 'premium') {
      setSelectedSubs(currentSubscribers)
      const subscribers = getClosestMinimumSubscribers(currentSubscribers)

      setPrice(PRICING[subscribers][currency])
    } else {
      setPrice(PRICING[selectedSubs][currency])
    }
  }, [currency])

  const openChat = function () {
    if (Beacon) {
      Beacon('open')
    }
  }

  function handleSubsChange(e) {
    const value = e.target.value

    if (value > 200000) {
      setContactSales(true)
    } else {
      setContactSales(false)
    }

    const result = Object.keys(PRICING).find((key) => {
      return value <= parseInt(key)
    })

    setPrice(PRICING[result][currency])
    setSelectedSubs(result)
  }

  function handleBuyClick() {
    if (contactSales) {
      openChat()
      return
    }
    router.push('/signup')
  }

  function handleSelectPlan() {
    if (currentPlan?.slug == 'premium' && selectedSubs == currentSubscribers)
      return
    selectPlan({
      plan_id: 'premium',
      subscribers: selectedSubs,
      currency: currency,
    })
  }

  function cta() {
    if (selectPlan) {
      if (!currentPlan) {
        return (
          <a href="#" onClick={handleSelectPlan} className={changePlanClasses}>
            Start free trial
          </a>
        )
      }

      if (currentPlan?.slug != 'premium') {
        return (
          <a href="#" onClick={handleSelectPlan} className={changePlanClasses}>
            Change Plan
          </a>
        )
      }

      return (
        <a
          href="#"
          onClick={handleSelectPlan}
          className={
            currentUser.company?.subscriptionStatus == 'trial'
              ? changePlanClasses
              : currentSubscribers != selectedSubs
              ? changePlanClasses
              : currentPlanClasses
          }
        >
          {currentUser.company?.subscriptionStatus == 'trial'
            ? 'Start subscription'
            : currentSubscribers != selectedSubs
            ? 'Change plan'
            : 'Current plan'}
        </a>
      )
    }

    if (contactSales) {
      return (
        <a
          href="#"
          onClick={handleBuyClick}
          className={classNames(
            'mt-6 block rounded-md bg-indigo-600 py-2 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
          )}
        >
          Contact us
        </a>
      )
    }

    return (
      <a
        href="/signup"
        className={classNames(
          'mt-6 block rounded-md bg-indigo-600 py-2 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
        )}
      >
        Start free trial
      </a>
    )
  }

  const includedFeatures = [
    'Emails routed through your own Amazon SES account',
    'Unlimited automation workflows',
    'Unlimited segmentations',
    'Unlimited custom fields',
    'AI assisted email subject line suggestions',
    'CampaignHQ email templates',
    '5 users included',
    'AI assisted campaign creation',
    'Reports available forever',
    'Live customer support & setup assistance',
  ]

  const currencies = ['USD', 'INR']
  const currencySymbols = {
    USD: '$',
    INR: '₹',
  }
  const handleCurrencyChange = (e) => {
    setCurrency(e.target.value)
  }

  return (
    <div className="">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl rounded-3xl ring-1 ring-gray-200 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">
              Premium Connect
            </h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Connect plan allows you to connect your own Amazon SES account or
              any other email service provider and send unlimited emails.
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">
                Everything included
              </h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {includedFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon
                    className="h-6 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="relative h-full rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <div className="text-sm font-semibold text-indigo-600">
                  <p className="text-md text-bold absolute left-0 top-0 right-0 rounded-t-2xl bg-green-500 px-3 py-2 leading-5 text-white">
                    No credit card required, cancel anytime
                  </p>
                  <div>7 days free trial</div>
                  <div className="mt-2 text-xs font-normal leading-5 text-gray-600">
                    100 emails per day limit in trial period
                  </div>
                  {showCurrency ? (
                    <div className="mt-6">
                      <select
                        id="currency"
                        name="currency"
                        className="mx-auto block w-1/3 rounded-md border-0 py-1 pl-3 pr-3 text-xs text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-indigo-600 sm:leading-6"
                        defaultValue={currency}
                        onChange={handleCurrencyChange}
                      >
                        {currencies.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : null}
                </div>
                <p className="mt-4 flex items-baseline justify-center gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">
                    {currencySymbols[currency]}
                    {nf.format(price)}
                  </span>
                  <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                    /month
                  </span>
                </p>
                <div>
                  <label htmlFor="steps-range" className="sr-only">
                    Range steps
                  </label>
                  <input
                    id="steps-range"
                    type="range"
                    min="500"
                    value={selectedSubs}
                    max="201000"
                    onChange={handleSubsChange}
                    step="500"
                    className="mt-6 h-3 w-full cursor-pointer appearance-none rounded-lg border bg-white accent-indigo-600"
                  />
                </div>
                <div className="mt-4 text-left text-xl font-semibold leading-6 text-gray-500">
                  {selectedSubs > 200000 ? '200,000+' : nf.format(selectedSubs)}{' '}
                  <span className="text-sm font-normal">subscribers</span>
                </div>
                <div className="mt-2 text-left text-xl font-semibold leading-6 text-gray-500">
                  Unlimited{' '}
                  <span className="text-sm font-normal">emails per month</span>
                </div>
                <div className="mt-2 text-left text-xl font-semibold leading-6 text-gray-500">
                  Unlimited{' '}
                  <span className="text-sm font-normal">
                    whatsapp messages per month
                  </span>
                </div>
                {loading ? (
                  <button
                    disabled={loading}
                    className={
                      currentSubscribers != selectedSubs
                        ? changePlanClasses
                        : currentPlanClasses
                    }
                  >
                    <span className="inline-block">
                      <ThreeDots
                        height="10"
                        width="30"
                        radius="10"
                        color={
                          currentPlan?.slug == 'premium' ? '#0075ff' : '#fff'
                        }
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      ></ThreeDots>
                    </span>
                  </button>
                ) : (
                  cta()
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
